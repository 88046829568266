import React from 'react';
import MDX from '@mdx-js/runtime';
import { PageWrapper, Header, Content } from '../shared/Page';
import { SectionProps } from './types';
import { ColorTheme } from '../constants/ColorTheme';

const components = {
  h1: (props) => <Header theme={ColorTheme.DarkPink} id={'upper-mongolia'} {...props} />,
  p: (props) => <Content theme={ColorTheme.DarkPink} {...props} />,
};

export function UpperMongolia({ theme }: SectionProps) {
  return (
    <PageWrapper padding={'0 0 2.5rem 0'} theme={theme}>
      <MDX components={components}>
        {`# EKSPEDICIJOS Į AUKŠTUTINĘ MONGOLIJĄ

Po 1975 metų Salvadoro Dali ekspedicijos į Aukštutinę Mongoliją, siurrealizmo, kaip metodo nebeįmanoma įvardinti tais terminais, kokiais jis buvo apibūdinamas iki tol. Nuo tada jis įgyja gilesnį ir specifiškesnį, būtent – ekspedicinį aspektą.

Taip pat Aukštutinėje Mongolijoje, vieną 2018 metų rudens vakarą, jurtoje prie puodelio sviestinės arbatos, gyvačių kerėtojas ir kupranugarių užkalbėtojas nutarė ieškoti Hanumano.

Kaip paaiškėjo vėliau, jie jau buvo ieškoję jo anksčiau ir buvo jį sutikę.
`}
      </MDX>
    </PageWrapper>
  );
}
