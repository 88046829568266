import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { ColorTheme } from '../constants/ColorTheme';
import { device } from '../constants/device';

const handleClick = () =>
  document.getElementById('society').scrollIntoView({ behavior: 'smooth' });

interface ButtonProps {
  isVisible: boolean;
}

const Button = styled.button<ButtonProps>`
  position: fixed;
  left: 1rem;
  bottom: 1rem;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  color: ${ColorTheme.DarkerYellow};
  border: 2px solid currentColor;
  background-color: ${ColorTheme.LightOrange};
  font-size: inherit;
  padding: 0.7rem;
  text-transform: uppercase;
  cursor: pointer;

  @media ${device.laptop} {
    border: none;
  }
`;

export function BackToTop() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);
  return (
    <Button isVisible={isVisible} onClick={handleClick}>
      <b>Į viršų</b>
    </Button>
  );
}
