import React from 'react';
import styled from 'styled-components';
import MDX from '@mdx-js/runtime';
import { device } from '../constants/device';
import { PageWrapper, Header, Content } from '../shared/Page';
import { SectionProps } from './types';

const ContentWrapper = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
    height: 50px;
  }
`;

export function Footer({ theme }: SectionProps) {
  return (
    <PageWrapper theme={theme}>
      <ContentWrapper />
    </PageWrapper>
  );
}
