import React from 'react';
import MDX from '@mdx-js/runtime';
import styled from 'styled-components';
import { ColorTheme } from '../constants/ColorTheme';
import { device } from '../constants/device';

import {
  PageWrapper,
  Header,
  Content,
  UnorderedList,
  ListItem,
} from '../shared/Page';
import { FollowOnFb } from '../shared/FollowOnFb';
import { SectionProps } from './types';
import { LanguagePicker, Menu } from '../heroSection';

const components = {
  h1: (props) => (
    <Header id={'society'} theme={ColorTheme.DarkerYellow} {...props} />
  ),
  p: (props) => <Content theme={ColorTheme.DarkerYellow} {...props} />,
  ul: (props) => <UnorderedList {...props} />,
  li: (props) => <ListItem {...props} />,
};

const MenuWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

const HeaderWrapper = styled.div`
  text-align: center;
`;

const Logo = styled.img`
  width: 100px;
  padding-top: 2.5rem;
  margin: 0 auto;

  @media ${device.laptop} {
    width: 180px;
  }
`;

export function Society({ theme }: SectionProps) {
  return (
    <PageWrapper padding={'0 0 2.5rem 0'} theme={theme}>
      <LanguagePicker />
      <HeaderWrapper>
        <Logo src="./flag.jpg" id={'society'} />
        <Header textAlign={'center'} theme={ColorTheme.DarkerYellow}>
          GAMTAKULTŪRINĖ DRAUGIJA
        </Header>
      </HeaderWrapper>
      <MDX components={components}>{`

Gamtakultūrinė draugija – tai laisva ir savarankiška organizacija, vienijanti kūrėjus, kurie siekia realizuoti kino ir teatro kalbų sintezę kaip iš esmės psichogeografinę kalbą bei savirefleksinę keliavimo formą, taip pat kiną ir teatrą kaip žaidimą.

`}</MDX>
      <br />
      <MenuWrapper>
        <Menu />
      </MenuWrapper>
      <Content textAlign={'center'}>
        <FollowOnFb />
      </Content>
    </PageWrapper>
  );
}
