import React from 'react';
import styled from 'styled-components';
import { ColorTheme } from '../constants/ColorTheme';
import { device } from '../constants/device';

const Languages = styled.ul`
  display: inline-flex;
  flex-direction: row;
  list-style: none;
  padding-inline-start: 0;
  color: ${ColorTheme.DarkerYellow};
  border: 5px solid currentColor;
  margin: 1.5rem;
`;

const LanguageOption = styled.li`
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  font-size: 1rem;
  padding: 0.5rem 1rem;

  :hover {
    background-color: ${ColorTheme.DarkestYelow};
  }

  @media ${device.laptop} {
    font-size: 1.2rem;
    padding: 0.5rem 1.5rem;
  }
`;

const LanguageLink = styled.a`
  color: currentColor;
`;

export function LanguagePicker() {
  return (
    <Languages>
      <LanguageOption>
        <LanguageLink href="/">LT</LanguageLink>
      </LanguageOption>
      <LanguageOption>
        <LanguageLink href="/en">EN</LanguageLink>
      </LanguageOption>
    </Languages>
  );
}
