import React from 'react';
import styled from 'styled-components';
import MDX from '@mdx-js/runtime';
import { PageWrapper, Header, Content } from '../shared/Page';
import { PageImage } from '../shared';
import { ColorTheme } from '../constants/ColorTheme';
import { SectionProps } from './types';
import { device } from '../constants/device';

const HeaderWrapper = styled.div`
  text-align: center;
`;

const IFrame = styled.iframe`
  display: block;
  margin: 24px auto 0;
  width: 90%;
  max-width: 560px;

  @media ${device.laptop} {
    margin: 24px auto 0;
    width: 560px;
  }
`;

const components = {
  p: (props) => <Content theme={ColorTheme.White} {...props} />,
};

export function Expeditions({ theme }: SectionProps) {
  return (
    <PageWrapper padding={'0 0 2.5rem 0'} theme={theme}>
      <HeaderWrapper>
        <Header
          textAlign={'center'}
          theme={ColorTheme.White}
          id={'expeditions'}
        >
          KINO EKSPEDICIJOS
        </Header>
      </HeaderWrapper>
      <br />
      <br />
      <PageImage src="expedicijos.jpg" />
      <br />
      <br />
      <MDX components={components}>{`

Mes ne tik keliaujame patys, bet ir kinas keliauja su mumis. Ekspedicinė kinematografija remiasi idėja, kad, kino priemonėmis vaizduojant ekspedicijas, reikia jose ir dalyvauti. Tokio filmavimo tikslas – atlikti jį tiesioginėse ir tikrose kelionės sąlygose. Tai neturi būti scenos pastatytos žiūrovui – pirmiausia jos turi būti patirtos. 

`}</MDX>
      <Content theme={ColorTheme.White} textAlign={'center'}>
        ________________________
      </Content>
      <br />
      <IFrame as="img" src="afisa.jpg" alt="" />
      <Content textAlign={'center'} theme={ColorTheme.White}>
        FILMAS „MEILĖ GAMTAI“, 2022
      </Content>
      <MDX components={components}>{`

Nuo 2019 metų įvyko vienuolika gamtakultūrinių kino ekspedicijų, kurių metu buvo filmuojamas meninis-dokumentinis filmas „Meilė gamtai“. Jame – ypatinga kelionė po Dubingių kraštą – senosios Lietuvos ištakų vietą, beieškant žalčių.

Filme tyrinėjamas žalčio simbolis, kuris savyje apjungia ir išreiškia skirtingas gamtakultūrinio pažinimo kryptis: gyvūną, jo gyvenamąją aplinką – mišką ir pelkes, archajinį totemą, mitologinę formą, liaudies meno motyvus, senovines buities praktikas, liaudies mediciną ir t. t.

Filmo siužetas nukelia į gilesnę mūsų krašto pažinimo tikrovę, kurioje keičiasi geografinės erdvės suvokimas, išnyksta aiški riba tarp to, kas matoma ir to, kas juntama. Kelionė tampa kartu ir vidiniu keliavimu.

Čia sekant žalčių pėdsakais, klaidžiojama po pelkes. Ekspedicijos komandą lydi vietinė gamtininkė. Sutiktos miškų apsuptyje esančio atokaus kaimo gyventojos pasidalina istorijomis apie šias vietas. Žengiame gilyn į girių gūdumą. Toliau kelią mums rodo laumės.

`}</MDX>
      <br />

      <IFrame
        width="560"
        height="315"
        src="https://www.youtube-nocookie.com/embed/Fq8C71vPV-8"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
      <IFrame
        width="560"
        height="315"
        src="https://www.youtube-nocookie.com/embed/aAS5IpYC0II"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
      <Content theme={ColorTheme.White} textAlign={'center'}>
        ________________________
      </Content>
      <br />
      <IFrame as="img" src="afisa2.jpg" alt="" />
      <Content textAlign={'center'} theme={ColorTheme.White}>
        TEATRO FILMAS „SAPNALAIKIS“, 2024
      </Content>
      <MDX components={components}>{`

Orientalistinė ekspedicija į vieną gražiausių Lietuvos kampelių – varnų gaudytojų kraštą Kuršių neriją. Tai – kelionė per skirtingus laikmečius bei skirtingas vietas. O svarbiausia – ši kelionė vyksta sapne.

Į šią ypatingą ekspediciją išsiruošėme dar 2024 metų vasarą. Tačiau jau iš karto supratome, jog reikės keliauti po visiškai kitokią Kuršių neriją. Jūros vėjo genami keliavome į laikus, kai klajojančios kopos užnešdavo smėlį prie kiekvieno namo slenksčio, o brisdamas ilgus kilometrus per baltus tyrus sutikdavai vos vieną kitą varnas gaudantį kopininką.  

Kadaise dailininkų taip pamėgtas XIX amžiaus pabaigos – XX amžiaus pradžios nerijos vėjų pustomų kopų kraštovaizdis čia susipina su svajomis apie tolimus Rytų kraštų dykumų smėlynus. Galbūt šį sapną sapnuoja keliautoja, o gal tai pakeleivių regimi miražai?

`}</MDX>
    </PageWrapper>
  );
}
