import React from 'react';
import styled from 'styled-components';
import MDX from '@mdx-js/runtime';
import { device } from '../constants/device';
import { ColorTheme } from '../constants/ColorTheme';
import { PageWrapper, Header, Content } from '../shared/Page';
import { PageImage } from '../shared';
import { SectionProps } from './types';

const ContentWrapper = styled.div`
  @media ${device.laptop} {
    display: flex;
  }
`;

const TextWrapper = styled.div`
  padding-bottom: 2.5rem;

  @media ${device.laptop} {
    padding-bottom: 0;
  }
`;

const ImageWrapper = styled.div`
  flex: 1 0 350px;
`;

const components = {
  h1: (props) => (
    <Header theme={ColorTheme.HeartRed} id={'flying-carpet'} {...props} />
  ),
  p: (props) => <Content theme={ColorTheme.HeartRed} color={'white'} {...props} />,
};

export function FlyingCarpet({ theme }: SectionProps) {
  return (
    <PageWrapper theme={theme}>
      <ContentWrapper>
        <TextWrapper>
          <MDX components={components}>{`# KELIONĖS SKRAIDANČIU KILIMU

Tarp kitų kelionės būdų mes naudojame vieną ypatingą keliavimo priemonę – skraidantį kilimą. Žodžių ir minčių žaismą paverčiame vaizdų ir veikimo žaismu.

Apibūdinti žaidimo sąvoką – sudėtinga. Akademinėje tradicijoje iš esmės yra keturios klasikinės ir keturios šiuolaikinės žaidimo teorijos. Kiekviena jų bando žaidimą įvardinti savo rakurso terminais.

Mūsų draugija kelia tris žaidimą nusakančius principus.

Pirma, žaidimas kaip tarpusavio realizacijos aplinkoje būdas ir mąstymo būsena.

Antra – žaidimas kaip tikrovės atributas.

Ir trečia, žaidimo neapibrėžtumas, kuris peržengia paties žaidimo sąvokos ribas.
`}</MDX>
        </TextWrapper>
        <ImageWrapper>
          <PageImage src="/magic-carpet.jpeg" />
        </ImageWrapper>
      </ContentWrapper>
    </PageWrapper>
  );
}
