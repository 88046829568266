import React from 'react'
import styled from 'styled-components'
import { device } from '../constants/device'

interface Props {
  src: string;
  id?: string;
}

const FullWidthImage = styled.img`
  display: block;

  @media ${device.laptop} {
    width: 100%;
  }
`

export function PageImage({ src, id }: Props) {
  return <FullWidthImage id={id} src={src} alt="" />
}
