import React from 'react';
import styled from 'styled-components';
import MDX from '@mdx-js/runtime';
import { device } from '../constants/device';
import { ColorTheme } from '../constants/ColorTheme';
import { PageWrapper, Header, Content } from '../shared/Page';
import { PageImage } from '../shared';
import { SectionProps } from './types';

const ContentWrapper = styled.div`
  @media ${device.laptop} {
    display: flex;
  }
`;

const TextWrapper = styled.div`
  padding-bottom: 2.5rem;

  @media ${device.laptop} {
    padding-bottom: 0;
  }
`;

const ImageWrapper = styled.div`
  flex: 1 0 350px;
`;

const components = {
  h1: (props) => (
    <Header theme={ColorTheme.DarkestYelow} id={'games'} {...props} />
  ),
  p: (props) => (
    <Content theme={ColorTheme.DarkestYelow} color={'white'} {...props} />
  ),
};

export function Games({ theme }: SectionProps) {
  return (
    <PageWrapper theme={theme}>
      <ContentWrapper>
        <TextWrapper>
          <Header theme={ColorTheme.DarkestYelow} id={'games'}>
            <span style={{ color: '#2f7400' }}>Ž</span>
            <span style={{ color: '#c40000' }}>A</span>
            <span style={{ color: '#ff9900' }}>I</span>
            <span style={{ color: '#00add0' }}>D</span>
            <span style={{ color: '#47b000' }}>I</span>
            <span style={{ color: '#006699' }}>M</span>
            <span style={{ color: '#ff4f09' }}>O</span>
            <span style={{ color: '#92d050' }}>L</span>
            <span style={{ color: '#ffc000' }}>O</span>
            <span style={{ color: '#e818ad' }}>G</span>
            <span style={{ color: '#e46c0a' }}>I</span>
            <span style={{ color: '#31859c' }}>J</span>
            <span style={{ color: '#ff0000' }}>A</span>
          </Header>
          <MDX components={components}>{`
Norėdami patikrinti savo žaidimo teoriją praktikoje ir bendradarbiaudami su Lietuvių literatūros ir tautosakos institutu bei Žaislų muziejumi, sukūrėme dokumentiką ludologijos, arba kitaip – žaidimų tyrimų, tematika.

Dokumentikoje nagrinėjami ne tik atskiri konkretūs žaidimai bei žaislai, bet ir platesnis paties žaidimo reiškinys, jo istorija ir formos Lietuvos bei pasaulio kontekste, pereinant iki dar bendresnės žaismo kategorijos analizės.

Žaislai ir žaidimai iš senojo Lietuvos kaimo, jų sąsajos su konkretaus krašto tyrinėjimais, taip pat vietos mitologija ir pasakomis vedė mus į praktinį žaidimo patyrimą.
`}</MDX>
        </TextWrapper>
        <ImageWrapper>
          <PageImage src="/vinjete.jpg" />
        </ImageWrapper>
      </ContentWrapper>
    </PageWrapper>
  );
}
