import './styles.css';
import React from 'react';
import { HeroSection } from './heroSection';
import { ColorTheme } from './constants/ColorTheme';
import {
  Nomad,
  Society,
  Expeditions,
  PassedSummerCamp,
  UpperMongolia,
  FlyingCarpet,
  Games,
  Footer
} from './sections';
import { BackToTop } from './shared/BackToTop';
import { LitExpeditions } from './sections/LitExpeditions';

export function App() {
  return (
    <>
      <HeroSection />
      <Society theme={ColorTheme.LightOrange} />
      <Nomad theme={ColorTheme.Green} />
      <UpperMongolia theme={ColorTheme.LightYellow} />
      <Expeditions theme={ColorTheme.DarkRed} />
      <FlyingCarpet theme={ColorTheme.ElectricBlue} />
      <LitExpeditions theme={ColorTheme.Pink} />
      <PassedSummerCamp theme={ColorTheme.DarkGreen} />
      <Games theme={ColorTheme.DarkerRed} />
      <Footer theme={ColorTheme.DarkerRed} />
      <BackToTop />
    </>
  );
}
