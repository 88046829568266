import React from 'react';
import styled from 'styled-components';
import MDX from '@mdx-js/runtime';
import { device } from '../constants/device';
import { ColorTheme } from '../constants/ColorTheme';
import { PageWrapper, Header, Content } from '../shared/Page';
import { PageImage } from '../shared';
import { SectionProps } from './types';

const ContentWrapper = styled.div`
  @media ${device.laptop} {
    display: flex;
  }
`;

const TextWrapper = styled.div`
  padding-bottom: 2.5rem;

  @media ${device.laptop} {
    padding-bottom: 0;
  }
`;

const ImageWrapper = styled.div`
  flex: 1 0 250px;
`;

const components = {
  h1: (props) => <Header theme={ColorTheme.DarkerYellow} id={'lit-expeditions'} {...props} />,
  p: (props) => <Content theme={ColorTheme.DarkerYellow} color={'white'} {...props} />,
};

export function LitExpeditions({ theme }: SectionProps) {
  return (
    <PageWrapper theme={theme}>
      <ContentWrapper>
        <ImageWrapper>
          <PageImage src="/eksp.jpeg" />
        </ImageWrapper>
        <TextWrapper>
          <MDX components={components}>{`# LITERATŪRINĖS EKSPEDICIJOS

Literatūrinis keliavimas yra ypatinga kelionės forma, mus nuvedanti į gilų ir itin sudėtingą pasakojimų ir kūrinių pasaulį, kur susipina vietovės, siužetai, veikėjų bei autorių istorijos. Taip keliaudami, kiekvieną kartą vis kitaip atrandame ir savo pačių vidinę kelionę.

Šios ekspedicijos mus nuosekliai veda į glaudžiai susijusias sritis – liaudies meną, tautosaką bei mitologiją.


`}</MDX>
        </TextWrapper>
      </ContentWrapper>
    </PageWrapper>
  );
}
