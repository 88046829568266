import styled from 'styled-components';
import { ColorTheme } from '../constants/ColorTheme';

const Link = styled.a`
  color: ${ColorTheme.DarkYellow};
  text-transform: ;
`;

export function FollowOnFb() {
  return (
    <Link
      href="https://www.facebook.com/Gamtakult%C5%ABrin%C4%97-draugija-101614475504261"
      target="_blank"
    >
      <b>Sekite mūsų naujienas čia</b>
    </Link>
  );
}
