import React from 'react';
import styled from 'styled-components';
import { Hero, Menu } from '.';
import { device } from '../constants/device';

const HeroImage = styled.header`
  height: 400px;
  background-image: url('kangchenjunga-1944.jpg');
  background-position: center center;
  background-repeat: no-repeat; 
  background-size: cover;

  @media ${device.laptop} {
    height: 100vh;
  }
`;

export function HeroSection() {
  return <HeroImage />;
}
